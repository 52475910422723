import React, { useState } from 'react'
import FAQitem from './FAQitem'

function FAQpage(props) {

  const [AnswerCheck,setAnswerCheck]=useState(-1)

  return (
    <div id='element' class='w-full h-[100vh] flex flex-col items-center  overflow-y-scroll space-y-3 py-5 md:px-10 px-5'>
      {
        props.FAQ.map((obj,index)=>
          <FAQitem obj={obj} index={index} AnswerCheck={AnswerCheck} setAnswerCheck={setAnswerCheck}/>
        )
      }
    </div>
  )
}

export default FAQpage