import React, { useEffect } from 'react'
import {MdDelete} from 'react-icons/md'
import {FaHandPointRight} from 'react-icons/fa'
import Swal from 'sweetalert2'

function SingleCategory(props) {

    function InsertPromptA()
    {
        Swal.fire({
            title:'Enter your category',
            input:'text',
            confirmButtonText:'Submit'
        }).then(function(prompt){
            if(prompt.isConfirmed)
            {
                if(prompt.value.length>0)
                {
                    props.setpromptInput({title:prompt.value,index:props.index,check:0})
                }
            }
        })
    }

    function InsertPromptB()
    {
        Swal.fire({
            title:'Enter your piece',
            input:'text',
            confirmButtonText:'Submit'
        }).then(function(prompt){
            if(prompt.isConfirmed)
            {
                if(prompt.value.length>0)
                {
                    props.setpromptInput({title:prompt.value,index:props.index,check:1})
                }
            }
        })
    }

  return (
    <div class='w-[50vh] h-full flex flex-col justify-between items-center relative border-[2px] border-[#884A39] ml-5 mr-5 mb-5'>
        <div class='w-full flex'>
            <div class='w-1/2  border-black p-3 flex flex-col'>
                <text class='font-bold border-e-[2px] border-b-[2px]'>CATEGORIES</text>
                {
                    props.current.categories.map((node)=>
                        <text class='flex items-center font-thin'>
                            <FaHandPointRight class='mr-2' color='#884A39'/>
                            {node}
                        </text>
                    )
                }
                </div>
                <div class='w-1/2  p-3 flex flex-col'>
                    <text class='border-b-[2px] border-l-[2px] font-bold'>Pieces</text>
                {
                    props.current.pieces.map((node)=>
                        <text class=' flex items-center font-thin'>
                            <FaHandPointRight class='mr-2' color='#884A39'/>
                            {node}
                        </text>
                    )
                }
                </div>
        </div>
        <div class='w-full h-1/3 bg-[#884A39] flex flex-col justify-evenly items-center'>
            <text class='text-md text-ellipsis text-white font-mono text-center overflow-hidden'>Content {props.index+1}: {props.current.name}</text>
            <div class='flex justify-between space-x-2'>
                <button class='px-2 py-1 bg-white text-[#884A39] rounded-lg text-md' onClick={InsertPromptA}>Insert Category</button>
                <button class='px-2 py-1 bg-white text-[#884A39] rounded-lg text-md' onClick={InsertPromptB}>Insert Piece</button>
            </div>
        </div>
    </div>
  )
} 

export default SingleCategory