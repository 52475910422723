import { BrowserRouter,Route,Routes } from 'react-router-dom';
import './App.css';
import CategoryPage from './Components/CategoryPage';
import Chatbot from './Components/Chatbot';
import { Fragment } from 'react';
import ShowCategories from './Components/ShowCategories';

function App() {



  return (
    <div class='w-full min-h-[100vh]'>
        <BrowserRouter>
          <Fragment>
            <Routes>
              <Route path='/' exact Component={CategoryPage}/>
              <Route path='/Chatbot' exact Component={Chatbot}/>
              <Route path='/showCat' exact Component={ShowCategories}/>
            </Routes>
          </Fragment>
        </BrowserRouter>
    </div>
  );
}

export default App;
