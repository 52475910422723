import React, { useEffect, useState } from 'react'
import '../App.css'
import { BiCategory } from 'react-icons/bi'
import { AiOutlineDelete, AiOutlineArrowRight } from 'react-icons/ai'
import SingleCategory from './SingleCategory'
import axios from 'axios';
import Swal from 'sweetalert2'

function CategoryPage() {

    const [ContentPieces, setContentPieces] = useState([])
    const [CategoryArr, setCategoryArr] = useState([])
    const [promptInput, setpromptInput] = useState(null)
    const [CurrentInput, setCurrentInput] = useState('')
    const [DeleteCategory, setDeleteCategory] = useState()
    const [Loading, setLoading] = useState(false)


    function handleInput(event) {
        setCurrentInput(event.target.value)
    }

    function AddCategory() {
        if (CurrentInput.length > 0) {
            let temp = []
            let temp2 = []
            setContentPieces(obj => [...obj, { name: CurrentInput, categories: temp, pieces: temp2 }])
        }
    }

    function DeleteCategoryFunction() {
        Swal.fire({
            title: 'Do you want to Delete all content?',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire('Saved!', '', 'success')
                setDeleteCategory(true)
            } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info')
            }
        })
    }

    function RedirectChatbot() {
        window.location.replace('/Chatbot')
    }

    useEffect(() => {
        console.log(ContentPieces)
        if (DeleteCategory == true) {
            Sendtobackend()
            setDeleteCategory(false)
        }
    }, [ContentPieces])

    useEffect(() => {
        if (DeleteCategory == true) {
            setContentPieces([])
            setCategoryArr([])
        }
    }, [DeleteCategory])

    async function Sendtobackend() {

        for (var i = 0; i < ContentPieces.length; i++) {
            if (ContentPieces[i].categories.length > 0) {
                for (var j = 0; j < ContentPieces[i].categories.length; j++) {
                    if ((CategoryArr.some(item => item.name == ContentPieces[i].categories[j])) == false) {
                        var temp = { name: ContentPieces[i].categories[j], content: [] }
                        CategoryArr.push(temp)
                    }
                }
            }
        }

        let TotCategoryArr = [...CategoryArr]

        for (var i = 0; i < ContentPieces.length; i++) {
            if (ContentPieces[i].categories.length > 0) {
                for (var j = 0; j < ContentPieces[i].categories.length; j++) {
                    if ((TotCategoryArr.some(item => item.name == ContentPieces[i].categories[j])) == true) {
                        var index = TotCategoryArr.findIndex(item => item.name == ContentPieces[i].categories[j])
                        let p1 = ContentPieces[i].pieces
                        let p2 = TotCategoryArr[index].content

                        console.log(index)
                        console.log(TotCategoryArr)

                        for (let m = 0; m < p1.length; m++) {
                            p2 = TotCategoryArr[index].content
                            if ((p2.includes(p1[m])) == false) {
                                let p3 = p2.concat(p1[m])

                                var data = []
                                for (let k = 0; k < TotCategoryArr.length; k++) {
                                    if (k == index) {
                                        data.push({ name: TotCategoryArr[k].name, content: p3 })
                                    }
                                    else {
                                        data.push(TotCategoryArr[k])
                                    }
                                }
                                TotCategoryArr = [...data]
                                console.log(TotCategoryArr)
                            }
                        }
                    }
                }
            }
        }

        setCategoryArr(TotCategoryArr)

        setLoading(true)
        const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/chat/cat`, {
            method: 'POST',
            body: JSON.stringify({ category: TotCategoryArr }),
            headers: {
                'Content-Type': 'application/json',
            },
        })

        if (res.status == 200) {
            console.log("DONE !")
            setLoading(false)
        }
        else {
            console.log("Error")
        }
    }


    useEffect(() => {
        if (promptInput != null) {
            let extra = []

            for (let i = 0; i < ContentPieces.length; i++) {
                if (i == promptInput.index) {
                    if (promptInput.check == 1) {
                        let temp = ContentPieces[i].pieces
                        temp.push(promptInput.title)
                        extra.push({ name: ContentPieces[i].name, categories: ContentPieces[i].categories, pieces: temp })
                    }
                    else if (promptInput.check == 0) {
                        let temp = ContentPieces[i].categories
                        temp.push(promptInput.title)
                        extra.push({ name: ContentPieces[i].name, categories: temp, pieces: ContentPieces[i].pieces })
                    }
                }
                else {
                    extra.push(ContentPieces[i])
                }
            }

            setContentPieces(extra)
        }
    }, [promptInput])

    useEffect(async () => {
        await axios.get(`${process.env.REACT_APP_BACKEND_URL}/chat/cat`)
            .then(function (response) {
                setCategoryArr(response.data)
            })
            .catch(function (error) {
                console.log(`Error : ${error}}`);
            });
    }, [])

    useEffect(() => {
        if (Loading) {
            Swal.fire({
                title: 'Saving Data',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
        }
        else {
            Swal.close()
        }
    }, [Loading])

    function RedirectShowCat() {
        localStorage.setItem('items', JSON.stringify(CategoryArr))
        window.location.replace('/showCat')
    }

    return (
        <div class='w-full h-[100vh] bg-[#FFFBEB] flex flex-col md:p-16 py-8 px-4 justify-between'>
            <div class='w-full flex flex-row justify-between'>
                <div class='md:text-5xl text-2xl flex items-center font-mono '>
                    <BiCategory class='mr-3' />
                    Content Pieces
                </div>
                <button class='flex items-center md:text-2xl text-lg font-bold text-[#884A39] hover:underline' onClick={RedirectChatbot}>
                    Go to Chatbot
                    <AiOutlineArrowRight size='1rem' class='ml-3' />
                </button>
            </div>
            <div class='w-full flex justify-between'>
                <div class='w-4/5 h-full flex'>
                    <input class='w-4/5 h-full md:p-4 p-2 outline-none border-[2px] border-[#884A39]' placeholder='Type new content piece...' onChange={handleInput}></input>
                    <button class='w-1/5 h-full md:p-4 p-2 bg-[#884A39] flex justify-center items-center text-white md:text-base text-xs' onClick={AddCategory}>
                        Add Content
                        <AiOutlineArrowRight class='ml-2 hidden md:block' />
                    </button>
                </div>
                <button class='w-[18%] h-full flex bg-red-700 justify-center items-center text-center text-white md:text-base text-xs' onClick={DeleteCategoryFunction}>
                    Delete All Content
                    <AiOutlineDelete class='ml-2 hidden md:block' />
                </button>
            </div>
            <div class='w-full p-3 flex space-x-3 justify-center'>
                <button class='md:w-[18%] w-[25%] rounded-md p-5 bg-blue-700 text-center text-white md:text-base text-xs' onClick={() => { Sendtobackend() }}>Save Content</button>
                <button class='md:w-[18%] w-[25%] rounded-md p-5 bg-pink-400 text-center text-white md:text-base text-xs' onClick={RedirectShowCat} >Show Categories</button>
            </div>
            <div id='element' class='w-full h-[52%] flex-row flex flex-wrap overflow-scroll'>
                {
                    ContentPieces.map((obj, index) =>
                        <SingleCategory current={obj} index={index} promptInput={promptInput} setpromptInput={setpromptInput} />
                    )
                }
            </div>
        </div>
    )
}

export default CategoryPage