import React, { useEffect, useState } from 'react'
import { AiOutlineArrowRight } from 'react-icons/ai'
import { BiCategory } from 'react-icons/bi'
import CategoryPage from './CategoryPage'

function ShowCategories() {

    const [data,setdata]=useState([])

    function RedirectContent()
    {
        window.location.replace('/')
    }

    useEffect(()=>
    {
        const items=JSON.parse(localStorage.getItem('items'))
        if(items)
        {
            setdata(items)
        }
    },[])

  return (
    <div class='w-full h-[100vh] bg-[#FFFBEB] flex flex-col md:p-16 py-8 px-4 justify-between'>
        <div class='w-full flex flex-row justify-between'>
            <div class='md:text-5xl text-2xl flex items-center font-mono '>
                    <BiCategory class='mr-3'/>
                    Categories
            </div>
            <button class='flex items-center md:text-2xl text-lg font-bold text-[#884A39] hover:underline' onClick={RedirectContent}>
                Go to Content Pieces 
                <AiOutlineArrowRight size='1rem' class='ml-3'/>
            </button>
        </div>
        <div class='w-full flex flex-col space-y-5'>
            {
                data.map((obj,index)=>
                    <div class='w-full flex flex-col'>
                        <text class='text-2xl font-bold'>Category {index+1}. {obj.name}</text>
                        <div class='w-full flex space-x-3'>
                            <text class='font-medium'>Content Pieces:</text> 
                        {
                            obj.content.map((obj2)=>
                             <text>{obj2},</text>   
                            )
                        }
                        </div>
                    </div>
                )
            }
        </div>
    </div>
  )
}

export default ShowCategories